import axios from "axios";

// 타입별 파일 리스트 조회
export const getFileInfoBy = async (fileType, name, token, call) => {
  //   const [files, setFiles] = useState(null);
  //   const [loading, setLoading] = useState(false);
  //   const [error, setError] = useState(null);

  //   try {
  // 요청이 시작 할 때에는 error 와 users 를 초기화하고
  // setError(null);
  // setFiles(null);
  // loading 상태를 true 로 바꿉니다.
  // setLoading(true);
  // const response = await axios.get(
  //   "/api/v1/file/get",
  //   {
  //     params: {
  //       fileName: name,
  //       fileType: fileType,
  //     },
  //   },
  //   {
  //     headers: { Authorization: "Bearer " + token },
  //   }
  // );
  // return response.data.data;
  //   } catch (e) {
  //     // setError(e);
  //   }
  //   setLoading(false);

  //////////////////////////////////
  axios
    .get(
      "/api/v1/file/get",
      {
        params: {
          fileName: name,
          fileType: fileType,
        },
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    )
    .then((res) => {
      call(res.data.data);
    })
    .catch((err) => {
      call(err);
    });
};

// // 이름으로 파일 리스트 조회
// export const getFileInfoByName = async (fileType, name, token) => {
//   const response = await axios.get(
//     "/api/v1/file/get",
//     {
//       params: {
//         fileName: name,
//         fileType: fileType,
//       },
//     },
//     {
//       headers: { Authorization: "Bearer " + token },
//     }
//   );
//   return response.data.data;
// };

// 다운로드 파일 이름을 추출하는 함수
const extractDownloadFilename = (response) => {
  const disposition = response.headers["content-disposition"];
  console.log(disposition);
  const fileName = decodeURI(
    disposition
      .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
      .replace(/['"]/g, "")
  );
  console.log(fileName);
  return fileName;
};

// export const getFile = async (fileId, token) => {
//   axios
//     .post(
//       "/api/v1/file/download",
//       {
//         fileId: fileId,
//       },
//       { headers: { "X-AUTH-TOKEN": token } },
//       { responseType: "blob" }
//     )
//     .then((response) => {
//       console.log(response);
//       console.log(response.data);

//       // 다운로드(서버에서 전달 받은 데이터) 받은 바이너리 데이터를 blob으로 변환합니다.
//       const blob = new Blob([response.data]);
//       // blob을 사용해 객체 URL을 생성합니다.
//       const fileObjectUrl = window.URL.createObjectURL(blob);

//       console.log(blob);
//       console.log(fileObjectUrl);

//       // blob 객체 URL을 설정할 링크를 만듭니다.
//       const link = document.createElement("a");
//       link.href = fileObjectUrl;
//       link.style.display = "none";

//       // 다운로드 파일 이름을 지정 할 수 있습니다.
//       // 일반적으로 서버에서 전달해준 파일 이름은 응답 Header의 Content-Disposition에 설정됩니다.
//       link.download = extractDownloadFilename(response);
//       // link.download = 'pts.zip';

//       // 다운로드 파일의 이름은 직접 지정 할 수 있습니다.
//       // link.download = "sample-file.xlsx";

//       // 링크를 body에 추가하고 강제로 click 이벤트를 발생시켜 파일 다운로드를 실행시킵니다.
//       document.body.appendChild(link);
//       link.click();
//       link.remove();

//       // 다운로드가 끝난 리소스(객체 URL)를 해제합니다.
//       window.URL.revokeObjectURL(fileObjectUrl);
//     });
// };

export const getFile = async (fileId, token) => {
  axios({
    method: "GET",
    url: "/api/v1/file/download?fileId=" + fileId,
    responseType: "blob",
    headers: { "X-AUTH-TOKEN": token },
  }).then((response) => {
    console.log(response);
    console.log(response.data);

    // 다운로드(서버에서 전달 받은 데이터) 받은 바이너리 데이터를 blob으로 변환합니다.
    const blob = new Blob([response.data]);
    // blob을 사용해 객체 URL을 생성합니다.
    const url = window.URL.createObjectURL(blob);

    // const url = window.URL.createObjectURL(
    //   new Blob([response.data], { type: response.headers["content-type"] })
    // );

    // blob 객체 URL을 설정할 링크를 만듭니다.
    const link = document.createElement("a");
    link.href = url;
    link.style.display = "none";

    // link.setAttribute(
    //   "download",
    //   extractDownloadFilename(response)
    // );

    // 다운로드 파일 이름을 지정 할 수 있습니다.
    // 일반적으로 서버에서 전달해준 파일 이름은 응답 Header의 Content-Disposition에 설정됩니다.
    link.download = extractDownloadFilename(response);

    // 링크를 body에 추가하고 강제로 click 이벤트를 발생시켜 파일 다운로드를 실행시킵니다.
    document.body.appendChild(link);
    link.click();
    link.remove();

    // 다운로드가 끝난 리소스(객체 URL)를 해제합니다.
    // window.URL.revokeObjectURL(fileObjectUrl);
  });
};
