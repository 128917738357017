import logo1 from "../../public/assets/icon/icon.png";
import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../layout/Auth";
import { getFile } from "../../lib/apis/file";
import { getFileInfoByName } from "../../lib/apis/file";
import Files from "../common/FileList";

function MainContent() {
  return (
    <main>
      <Files fileType={null} />
    </main>
  );
}

// function Files() {
//   const { searchItem, searchFlag, token } = useContext(AppContext);
//   const [files, setFiles] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   // 파일 조회
//   const fetchFiles = async () => {
//     try {
//       // 요청이 시작 할 때에는 error 와 users 를 초기화하고
//       setError(null);
//       setFiles(null);
//       // loading 상태를 true 로 바꿉니다.
//       setLoading(true);
//       let param = searchItem;
//       if (param.trim() === "") return;
//       console.log("searchItem4:" + param);
//       console.log("searchItem4:" + token);
//       const response = await getFileInfoByName(props.fileType, param, token);
//       console.log(response.length);
//       if (response.length !== 0) {
//         setFiles(response); // 데이터는 response.data 안에 들어있습니다.
//       }
//     } catch (e) {
//       setError(e);
//     }
//     setLoading(false);
//   };

//   useEffect(() => {
//     fetchFiles();
//   }, [searchFlag]);

//   const downloadFile = async (filename, e) => {
//     e.target.disabled = true;
//     e.target.classList.add("disabled");
//     setTimeout(function () {
//       e.target.disabled = false;
//       e.target.classList.remove("disabled");
//     }, 3000);

//     // 파일 다운로드
//     getFile(filename, token);
//   };

//   if (loading) return <div>로딩중..</div>;
//   if (error) return <div>에러가 발생했습니다</div>;
//   if (!files) return null;
//   return (
//     <ul>
//       <div className="mainDiv">
//         {files.length !== 0
//           ? files.map((file) => (
//               <div className="post-container" key={file.fileId}>
//                 <div className="post">
//                   <div className="post-content">
//                     <div className="test2">
//                       <img
//                         className="fileImg"
//                         src={file.fileImagePath}
//                         alt="PICTURE"
//                       />
//                     </div>
//                     <div className="file-info-name">
//                       <p className="fileName">{file.fileName}</p>
//                     </div>
//                     <div className="file-info-verson">
//                       <p className="fileVersion">{file.fileVersion}</p>
//                     </div>
//                     <div className="file-info-button">
//                       <button
//                         className="fileButton"
//                         onClick={(e) => downloadFile(file.fileId, e)}
//                       >
//                         INSTALL
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))
//           : "데이터가 존재하지 않습니다."}
//       </div>
//     </ul>
//   );
// }

export default MainContent;
