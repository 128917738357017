import "./App.css";
import React, { useState, useEffect, createContext } from "react";
import AppsContent from "./components/contents/AppsContents";
import SystemsContent from "./components/contents/SystemsContents";
import SearchContents from "./components/contents/SearchContents";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Auth from "./layout/Auth";
import NoAuth from "./layout/NoAuth";
import Main from "./components/Main";

export const AppContext = createContext();

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Auth />}>
          <Route path="" element={<Main />}>
            <Route path="Systems" element={<SystemsContent />} />
            <Route path="" element={<AppsContent />} />
            <Route path="SearchContents" element={<SearchContents />} />
          </Route>
        </Route>
        <Route path="/no" element={<NoAuth />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
