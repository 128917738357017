import { useEffect, useState, createContext } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { getAuth } from "../lib/apis/auth";
import { getAuthPost } from "../lib/apis/auth";
export const AppContext = createContext();

function Auth() {
  const [searchItem, setSearchItem] = useState("");
  const [searchFlag, setSearchFlag] = useState(false);
  const [token, setToken] = useState("");

  const changeSetSearch = (val) => {
    setSearchItem(val);
  };

  const changeSetSearchFlag = () => {
    setSearchFlag(!searchFlag);
  };

  let authKey = null;
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  let name = params.get("name");
  if (name != null) {
    authKey = name;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAuthPost(authKey);

        if (result === "") navigate("/no");
        setToken(result);
      } catch (error) {
        console.log("fetchData 에러:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <AppContext.Provider
      value={{
        searchItem,
        changeSetSearch,
        searchFlag,
        changeSetSearchFlag,
        token,
      }}
    >
      <Outlet />
    </AppContext.Provider>
  );
}

export default Auth;
