import "../App.css";
import React, { useState, createContext, useContext } from "react";
import Navigation from "../components/navigation/Navigation";
import Footer from "../components/footer/Footer";
import { Outlet, useNavigate } from "react-router-dom";
import { getAuth } from "../lib/apis/auth";
import axios from "axios";

import { AppContext } from "../layout/Auth";
function Main() {
  const navigate = useNavigate();
  const { searchItem, searchFlag, token } = useContext(AppContext);

  if (token == null) {
    navigate("/no");
  }

  // const [token, setToken] = useState("");

  // let authKey = null;
  // const params = new URLSearchParams(window.location.search);
  // let name = params.get("name");
  // if (name != null) {
  //   authKey = name;
  // }

  // const fetchData = async () => {
  //   try {
  //     console.log("메인!!!!!!");
  //     const result = await getAuth(authKey);

  //     setToken(result);
  //   } catch (error) {
  //     console.log("fetchData 에러:", error);
  //   }
  // };

  // fetchData();
  // console.log(authKey);
  // axios
  //   .get("/api/v1/auth/check?authKey=" + authKey)
  //   .then((res) => {
  //     if (
  //       res.data.data.accessToken !== undefined &&
  //       res.data.data.accessToken !== null
  //     ) {
  //       console.log("2222");
  //       console.log(authKey);
  //       console.log(res.data.data.accessToken);
  //       setToken(res.data.data.accessToken);
  //     } else {
  //       // setToken("");
  //     }
  //   })
  //   .catch((err) => {
  //     // setToken("");
  //   });

  return (
    <div className="App">
      <div className="content">
        <Navigation />
      </div>
      <Outlet />
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Main;
