import React, { useState, useEffect, useContext } from "react";
import { getFile } from "../../lib/apis/file";
import { AppContext } from "../../layout/Auth";
import { getFileInfoBy } from "../../lib/apis/file";
import test from "../../public/assets/icon/icon.png";

function Files(props) {
  const { searchItem, searchFlag, token } = useContext(AppContext);
  const [files, setFiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // 파일 조회
  const fetchFiles = async () => {
    try {
      // 요청이 시작 할 때에는 error 와 users 를 초기화하고
      setError(null);
      setFiles(null);
      // loading 상태를 true 로 바꿉니다.
      setLoading(true);
      // const response = await getFileInfoBy(props.fileType, searchItem, token);
      // setFiles(response);

      getFileInfoBy(props.fileType, searchItem, token, (d) => {
        setFiles(d);
      });
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchFiles();
  }, [searchFlag]);

  const downloadFile = (fileId, e) => {
    e.target.disabled = true;
    e.target.classList.add("disabled");
    setTimeout(function () {
      e.target.disabled = false;
      e.target.classList.remove("disabled");
    }, 3000);

    // 파일 다운로드
    getFile(fileId, token);
  };

  if (loading) return <div>로딩중..</div>;
  if (error) return <div>에러가 발생했습니다</div>;
  if (!files) return null;
  return (
    <ul>
      <div className="mainDiv">
        {files.length !== 0
          ? files.map((file) => (
              <div className="post-container" key={file.fileId}>
                <div className="post">
                  <div className="post-content">
                    <div className="file-info-img">
                      <img
                        className="fileImg"
                        src={test} //{file.fileImagePath}
                        alt="PICTURE"
                      />
                    </div>
                    <div className="file-info-name">
                      <p className="fileName">{file.fileName}</p>
                    </div>
                    <div className="file-info-verson">
                      <p className="fileVersion">{file.fileVersion}</p>
                    </div>
                    <div className="file-info-button">
                      <button
                        className="fileButton"
                        onClick={(e) => downloadFile(file.fileId, e)}
                      >
                        INSTALL
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : "데이터가 존재하지 않습니다."}
      </div>
    </ul>
  );
}

export default Files;
