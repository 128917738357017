import "./Navigation.css";
import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { AppContext } from "../Main";
import { AppContext } from "../../layout/Auth";
import { FaSearch } from "react-icons/fa";

function Navigation() {
  let data = [
    { url: "/SYSTEMS", name: "SYSTEMS" },
    { url: "/", name: "APPS" },
  ];

  // const [file, setFiles] = useState(null);
  // const [searchItem, setSearchItem] = useState("");
  const [name, setName] = useState("");
  // const [error, setError] = useState(null);
  const { changeSetSearch, changeSetSearchFlag, searchItem } =
    useContext(AppContext);
  const navigate = useNavigate();
  const onSubmit = async () => {
    // try {
    changeSetSearchFlag();
    // setError(null);
    // setFiles(null);
    // const response = await axios.get("/api/v1/file/" + name + "/get");

    // setFiles(response.data);
    // console.log(response.data.data);
    // } catch (e) {
    //   setError(e);
    // }
  };
  // const [inputText, setInputText] = useState("");
  const onChangeName = (e) => {
    console.log(e.target.value);
    changeSetSearch(e.target.value);
    setName(e.target.value);
    // setInputText(e.target.value);
    e.target.focus();
  };

  const activeEnter = (e) => {
    if (e.key === "Enter") {
      console.log("name::");
      onSubmit(name);
      navigate("/SearchContents");
    }
  };

  return (
    <nav>
      {data.map((item, idx) => {
        return (
          <Link key={idx} to={item.url} value={idx} className="linkNameBtn">
            <p className="linkName">{item.name}</p>
          </Link>
        );
      })}
      <div className="naviInputBox">
        <input
          className="naviInput"
          onChange={onChangeName}
          onKeyDown={(e) => activeEnter(e)}
          value={name}
        />
      </div>
      <div className="user-actions">
        <Link to="/SearchContents">
          <button
            className="headerBotton"
            type="button"
            onClick={() => {
              onSubmit(name);
            }}
          >
            <FaSearch className="icon" size={20} />
          </button>
        </Link>
      </div>
    </nav>
  );
}

export default Navigation;
