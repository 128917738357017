import "./MainContent.css";
import React, { useState, useContext } from "react";
import Files from "../common/FileList";
import { AppContext } from "../../layout/Auth";

function MainContent() {
  const { changeSetSearch } = useContext(AppContext);
  changeSetSearch("");

  return (
    <main>
      <Files fileType="10" />
    </main>
  );
}

export default MainContent;
