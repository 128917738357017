import "./Footer.css";

function Footer() {
  return (
    <footer>
      <p>COPYRIGHT (C)2016 SCSPRO., Ltd. All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;
