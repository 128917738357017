import axios from "axios";

// export const getAuth = async (authKey) => {
//   try {
//     const res = await axios.get("/api/v1/auth/check?name=" + authKey);
//     console.log(res);
//     if (
//       res.data.data.accessToken !== undefined &&
//       res.data.data.accessToken !== null
//     ) {
//       localStorage.setItem("token", res.data.data.accessToken);

//       return res.data.data.accessToken;
//     } else {
//       return "";
//     }
//   } catch (err) {
//     console.log(new Error("An error occurred"));
//     console.log(err);
//     return "";
//   }
// };

export const getAuthPost = async (data) => {
  console.log("here");
  const url = "/api/v1/auth/check/post";
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  console.log(data);
  const data1 = new FormData();
  data1.append("serialNo", data);
  data1.append("password", "123");

  try {
    const res = await axios.post(url, data1, config);
    console.log(res);
    if (
      res.data.data.accessToken !== undefined &&
      res.data.data.accessToken !== null
    ) {
      localStorage.setItem("token", res.data.data.accessToken);
      return res.data.data.accessToken;
    } else {
      return "";
    }
  } catch (err) {
    console.log(new Error("An error occurred"));
    console.log(err);
    return "";
  }
};
